import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import BaseLayout from "../components/global/BaseLayout";
import TransparentHeader from "../components/global/TransparentHeader";

const imagencod = "https://firebasestorage.googleapis.com/v0/b/imagenes-2d595.appspot.com/o/post%20publicitarios%2FDavid%20Painting%20and%20Handyman%20Services%20-%20donation.jpg?alt=media&token=346c3758-526a-4c6d-b244-afecec29d708"

function Donations() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <BaseLayout PageName="Donations">
        <div className="md:max-w-full w-full">
          <TransparentHeader
            headertitle="Donations"
            Subheader="Donations"
            bgimg={`${rpdata?.gallery?.[29]}`}
          />
                </div>
        <div className="flex justify-center flex-col md:flex-row items-center space-x-0 w-[100%] mx-auto py-10 donation lg:px-10 mb-16 lg:mb-20">
        <div>
            <h3 className="underline decoration-sky-500">Specify that it is a donation.</h3>
            <img className="w-[700px]" src={imagencod}></img>

            <p className="bg-[#0EA5E9] text-white">We Donate to Other Poor Countries, This Year It Was Mexico's Turn.</p>
        </div>
        </div>
        </BaseLayout>


    );
  }
  
  export default Donations;